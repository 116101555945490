import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    display: 'block',
    paddingRight: 0,
  },
}));

export default Content;
