import ChangePrefLink from './primitives/PrefLink';
import DummyTiles from './DummyTiles';
import ForYouUpsell from 'components/Banners/ForYouUpsell';
import Head from './Head';
import HomeHero from './HomeHero';
import HomeTiles from './HomeTiles';
import ImproveRecommendations from './ImproveRecommendations';
import MediaServerImage from 'components/MediaServerImage';
import MobileLink from './primitives/MobileLink';
import P from 'primitives/Typography/BodyCopy/P';
import PageBody, { MainStyles, ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlaylistPersonalized from 'components/PlaylistPersonalized';
import Section from 'primitives/Section';
import TilesUnderAd from './primitives/TilesUnderAd';
import { Component } from 'react';
import {
  fetchInitialRecs as fetchInitialRecsAction,
  fetchRecs as fetchRecsAction,
} from 'state/Recs/actions';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { navigate } from 'state/Routing/actions';
import { throttle } from 'lodash-es';
import type { Playlist } from 'state/Playlist/types';
import type { Station } from 'state/Stations/types';

// TODO: Once web-static-s3 is proxied through fastly remove this and use updated web-config (update it if needed)
const promoUrl =
  'http://ihr-static-web.s3-website-us-east-1.amazonaws.com/assets/home_promo.png';

type Props = {
  appLink: string;
  country: string;
  favoriteStations: Array<Record<string, any>>;
  fetchInitialRecs: typeof fetchInitialRecsAction;
  fetchRecs: typeof fetchRecsAction;
  isAnonymous: boolean;
  isDefaultRecs: boolean;
  limit: number;
  personalizedPlaylistFlag: boolean;
  playlists: Array<Playlist>;
  profileId: number;
  recs: Array<Station & { reasonId: number; reasonType: string }>;
  forYouRecs: Array<unknown>;
  requestFollowedPodcasts: () => void;
  translate: IGetTranslateFunctionResponse;
  fetchGenrePreferences: () => Promise<void>;
  selectedGenres: { [id: string]: boolean | undefined };
};

type State = {
  moreTiles: number;
};

class ForYou extends Component<Props, State> {
  state = { moreTiles: 24 };

  onRecsClicked = throttle(() => {
    const { fetchRecs } = this.props;
    this.setState(({ moreTiles }) => ({ moreTiles: moreTiles + 12 }));
    fetchRecs({
      limit: this.props.limit + 1,
      merge: true,
      offset: this.props.forYouRecs.length - 1,
    });
  }, 100);

  static defaultProps = {
    limit: 15,
  };

  async componentDidMount() {
    const {
      fetchGenrePreferences,
      fetchInitialRecs,
      requestFollowedPodcasts,
      selectedGenres,
    } = this.props;

    requestFollowedPodcasts();
    if (!selectedGenres || !Object.keys(selectedGenres).length)
      await fetchGenrePreferences();
    // we access props directly here so that we can see updates caused by fetchGenrePreferences
    if (!Object.keys(this?.props?.selectedGenres ?? {}).length) {
      navigate({ path: '/genre-options/' });
    } else {
      fetchInitialRecs();
    }
  }

  mainStyles: MainStyles = ({ mediaQueryBuilder, theme }) => ({
    display: 'inline-block',
    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      marginTop: 0,
      paddingRight: '0 !important', // JC - WEB-11807 - need important otherwise is overroad by Section primitive.
    },
  });

  render() {
    const {
      appLink,
      country,
      translate,
      isAnonymous,
      isDefaultRecs,
      favoriteStations,
      personalizedPlaylistFlag,
      playlists,
      profileId,
      recs,
    } = this.props;
    const tilesInRow = 3;
    const tilesInRowUnderAd = tilesInRow + 1;

    const { moreTiles } = this.state;
    const recsLoading = isDefaultRecs && !isAnonymous;
    const followedPlaylists = playlists.filter(playlist => playlist.followed);

    const appsPromo =
      country === 'US' ?
        <MobileLink
          target="_blank"
          title={translate('iHeartRadio Mobile Apps')}
          to={appLink}
        >
          <MediaServerImage
            alt="iHeartRadio Apps Promo"
            data-test="home-promo-image"
            src={promoUrl}
          />
        </MobileLink>
      : null;

    const wrappedContent = [
      <TilesUnderAd data-test="tiles-under-ad" key="titlesUnderAd">
        {recsLoading ?
          <DummyTiles
            hasBottomMargin
            numTiles={12}
            tilesInRow={tilesInRowUnderAd}
          />
        : <HomeTiles
            favoriteStations={favoriteStations}
            followedPlaylists={followedPlaylists}
            profileId={profileId}
            recs={recs.slice(12, moreTiles)}
            tilesInRow={tilesInRowUnderAd}
          />
        }
      </TilesUnderAd>,
      <ImproveRecommendations
        key="improveRecommendations"
        onRecsClicked={this.onRecsClicked}
      />,
    ];

    const personalizedPlaylist =
      !isAnonymous && personalizedPlaylistFlag ?
        <PlaylistPersonalized
          dataTest="personalized-playlists"
          itemSelectedLocation="for_you|personalized_playlist|tile"
          name={translate('Made for You')}
          playedFrom={PLAYED_FROM.DIR_PLAYLIST_MAIN_FEATURED}
          subtitleLines={2}
          tilesInRow={3}
          titleLines={1}
        />
      : null;

    return (
      <>
        <HomeHero />
        <Head />
        <PageBody
          banner={<ForYouUpsell />}
          dataTest={ViewName.ForYou}
          hasHero
          mainStyles={this.mainStyles}
          more={appsPromo}
          personalizedPlaylist={personalizedPlaylist}
          title={translate('Listen to Stations For You')}
          wrappedContent={wrappedContent}
        >
          <Section type="top">
            <P color={colors => colors.gray.medium!}>
              {translate('Change genre preferences {prefLink}', {
                prefLink: (
                  <ChangePrefLink
                    dataTest="change-genre-pref"
                    to="/genre-options/"
                  >
                    {translate('here')}
                  </ChangePrefLink>
                ),
              })}
            </P>
          </Section>
          {recsLoading ?
            <DummyTiles hasBottomMargin numTiles={12} tilesInRow={tilesInRow} />
          : <HomeTiles
              favoriteStations={favoriteStations}
              followedPlaylists={followedPlaylists}
              profileId={profileId}
              recs={recs.slice(0, 12)}
              tilesInRow={tilesInRow}
            />
          }
        </PageBody>
      </>
    );
  }
}

export default ForYou;
