/* eslint-disable react/no-array-index-key */
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import NavLink from 'components/NavLink';
import NewEpisodeBadge from 'components/NewEpisodeBadge/NewEpisodeBadge';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import PlaylistImage from 'components/MediaServerImage/PlaylistImage';
import RecReason from 'components/RecReason';
import Tile from 'components/Tile/Tile';
import TileDescription from 'components/TileDescription';
import Tiles from 'components/Tiles/Tiles';
import TilesImageWrapper from 'components/Tile/primitives/TilesImageWrapper';
import { get } from 'lodash-es';
import { getDropdown } from 'components/Dropdown/helpers';
import { isPlaylist } from 'state/Playlist/helpers';
import { Playlist } from 'state/Playlist/types';
import { Station } from 'state/Stations/types';
import { STATION_TYPE } from 'constants/stationTypes';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

function HomeTiles({
  favoriteStations,
  followedPlaylists,
  profileId,
  recs,
  tilesInRow,
}: {
  favoriteStations: Array<Record<string, any>>;
  followedPlaylists: Array<Playlist>;
  profileId: number;
  recs: Array<Station & { reasonId: number; reasonType: string }>;
  tilesInRow?: number;
}) {
  const imgWidth = 240;
  return (
    <Tiles tilesInRow={tilesInRow}>
      {recs.map((rec, i) => {
        const {
          description,
          name,
          newEpisodeCount,
          playedFrom,
          seedId,
          seedType,
          reasonId,
          reasonType,
          title,
          url,
          imgUrl,
        } = rec;
        const followedStation =
          favoriteStations ?
            favoriteStations.some(station => {
              const mediaId =
                get(station, 'seedId') || get(station, 'artistId');
              return mediaId === seedId;
            })
          : false;
        const followedPlaylist = followedPlaylists.some(
          playlist => playlist.seedId === seedId,
        );
        const followed = followedPlaylist || followedStation;
        const recTitleMap = {
          [`${STATION_TYPE.ARTIST}`]: `${name} Radio`,
          [`${STATION_TYPE.PODCAST}`]: title,
        };

        return (
          <Tile
            dropdown={getDropdown(followed, profileId, rec)}
            isRoundImage={seedType === STATION_TYPE.ARTIST}
            key={`for-you-page-top-section|${seedId}|${i}`}
            subTitle={
              <TileDescription
                description={description}
                stationId={String(seedId)}
                stationType={seedType}
              />
            }
            tilesInRow={tilesInRow}
            title={recTitleMap[seedType] ? recTitleMap[seedType] : name}
            url={url}
          >
            <RecReason
              id={reasonId}
              seedType={seedType}
              showDescriptionGradient={seedType !== STATION_TYPE.ARTIST}
              type={reasonType}
            />
            <NavLink css={{ display: 'block', position: 'relative' }} to={url}>
              <NewEpisodeBadge newEpisodeCount={newEpisodeCount} />
              <PlayButtonContainerPrimitive>
                <PlayButton
                  className="play"
                  deferPlay={!!url}
                  playedFrom={playedFrom}
                  seedId={seedId}
                  stationId={seedId}
                  stationType={seedType}
                />
              </PlayButtonContainerPrimitive>
              <TilesImageWrapper liveTile={seedType === 'live'}>
                {isPlaylist(seedType) ?
                  <PlaylistImage
                    alt={name}
                    aspectRatio={1}
                    src={imgUrl}
                    width={imgWidth}
                  />
                : <CatalogImage
                    alt={name}
                    aspectRatio={1}
                    id={seedId}
                    src={imgUrl}
                    type={seedType}
                    width={imgWidth}
                  />
                }
              </TilesImageWrapper>
            </NavLink>
          </Tile>
        );
      })}
    </Tiles>
  );
}

export default HomeTiles;
