import Button from './primitives/XButton';
import classnames from 'classnames';
import Icon from './Icon';
import { MouseEvent } from 'react';

type Props = {
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

function XButton({ onClick, className }: Props) {
  return (
    <Button
      aria-label="close"
      className={classnames(className)}
      data-test="close-button"
      onClick={onClick}
    >
      <Icon />
    </Button>
  );
}

export default XButton;
