import styled from '@emotion/styled';

const XButton = styled('button')(({ theme }) => ({
  backgroundColor: theme.colors.transparent.primary,
  border: 'none',
  cursor: 'pointer',
  display: 'inline-block',
  opacity: 0.6,
  margin: 0,
  padding: 0,
}));

export default XButton;
