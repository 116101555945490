import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.white.primary,
  borderBottom: `solid 1px ${theme.colors.gray.light}`,
  color: theme.colors.gray['400'],
  fontSize: '1.6rem',
  margin: '0 0 3rem',
  padding: '3rem',
  position: 'relative',
  textAlign: 'left',
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    fontSize: '1.4rem',
    padding: '1.5rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['370'])]: {
    fontSize: '1.2rem',
    padding: '1rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    paddingBottom: '2rem',
  },
}));

export default Root;
