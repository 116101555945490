import RecReason from './RecReason';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { genresRequest } from 'state/Genres/actions';
import { getAmpUrl, getCountryCode } from 'state/Config/selectors';
import { getGenres } from 'state/Genres/selectors';
import { localize } from 'redux-i18n';

const mapStateToProps = createStructuredSelector({
  ampUrl: getAmpUrl,
  countryCode: getCountryCode,
  genres: getGenres,
});

const mapDispatchToProps = {
  genresRequest,
};

export default localize('translate')(
  connect(mapStateToProps, mapDispatchToProps)(RecReason),
);
