import useTheme from 'contexts/Theme/useTheme';

function Icon() {
  const theme = useTheme();

  return (
    <svg fill={theme.colors.gray.dark} viewBox="674.7 1322.7 100 100">
      <polygon
        points="761,1341.4 755.5,1335.9 724.8,1366.6 694.1,1335.9 688.5,1341.4 719.1,1372 688.4,1402.8 693.9,1408.3
  	724.6,1377.6 755.3,1408.3 760.8,1402.8 730.2,1372.2 "
      />
    </svg>
  );
}

export default Icon;
