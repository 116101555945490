import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const TilesUnderAd = styled('div')(({ theme }) => ({
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    marginTop: '-5rem',
  },
}));

export default TilesUnderAd;
