import AlbumDescription from './Album';
import AllDescription from './Description';
import ArtistDescription from './Artist';
import DefaultDescription from './Default';
import FavoritesDescription from './Favorites';
import LiveDescription from './Live';

export default AllDescription;

export {
  ArtistDescription,
  AlbumDescription,
  LiveDescription,
  DefaultDescription,
  FavoritesDescription,
};
