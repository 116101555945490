import Content from 'components/Banners/primitives/Content';
import FilledButton from 'primitives/Buttons/FilledButton';
import Heading from 'components/Banners/primitives/Heading';
import Root from 'components/Banners/primitives/Root';
import Text from 'components/Banners/primitives/Text';
import XButton from 'components/Buttons/XButton/XButton';
import XButtonPrimitive from 'components/Banners/primitives/XButton';
import type { ReactElement } from 'react';

type Props = {
  allAccessPreview: boolean;
  buttonText: string;
  contentText: string;
  dataTest: string;
  headingText: string;
  onClick: () => void;
  onClose: () => void;
};

function Banner({
  headingText,
  contentText,
  buttonText,
  onClick,
  onClose,
  dataTest,
  allAccessPreview,
}: Props): ReactElement {
  return (
    <Root data-test={dataTest}>
      <Heading>{headingText}</Heading>
      <Content>
        <Text>{contentText}</Text>
        <FilledButton
          aria-label={buttonText}
          data-test="for-you-banner-button"
          isBlock
          marginTop="1rem"
          onClick={onClick}
          styleType="forYouUpsell"
        >
          {buttonText}
        </FilledButton>
      </Content>
      {allAccessPreview ? null : (
        <XButtonPrimitive>
          <XButton onClick={onClose} />
        </XButtonPrimitive>
      )}
    </Root>
  );
}

export default Banner;
