import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Text = styled('div')(({ theme }) => ({
  padding: 0,
  width: '100%',
  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    display: 'block',
    paddingRight: 0,
  },
}));

export default Text;
