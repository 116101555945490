export const MARKET = 'MARKET';
export const GENRE = 'GENRE';
export const FAVORITES = 'FAVORITES';

export const MOST_PLAYED_LIVE_STATION = 'MOST_PLAYED_LIVE_STATION';
export const RECENT_LIVE_STATION = 'RECENT_LIVE_STATION';
export const FB_LIVE_STATION = 'FB_LIVE_STATION';

export const MOST_PLAYED_CUSTOM_STATION = 'MOST_PLAYED_CUSTOM_STATION';
export const RECENT_CUSTOM_STATION = 'RECENT_CUSTOM_STATION';
export const FB_CUSTOM_STATION = 'FB_CUSTOM_STATION';

export const DEFAULT = 'DEFAULT';

export const LIVE_TYPES = [
  MOST_PLAYED_LIVE_STATION,
  RECENT_LIVE_STATION,
  FB_LIVE_STATION,
];
export const CUSTOM_TYPES = [
  MOST_PLAYED_CUSTOM_STATION,
  RECENT_CUSTOM_STATION,
  FB_CUSTOM_STATION,
];
export const FACEBOOK_TYPES = [FB_LIVE_STATION, FB_CUSTOM_STATION];
