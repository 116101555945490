import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import {
  getCustomRadioEnabled,
  getOnDemandEnabled,
} from 'state/Features/selectors';
import { getShareMessage } from 'constants/sharing';
import { Helmet } from 'react-helmet';
import { translateKeywords } from 'utils/metaUtils';
import { useSelector } from 'react-redux';

export default function Head() {
  const customRadioEnabled = useSelector(getCustomRadioEnabled);
  const onDemandEnabled = useSelector(getOnDemandEnabled);
  const translate = useTranslate();

  const title = translate(
    'iHeart: Listen to Free Radio Stations & Music Online',
  );

  const keywords = translateKeywords(
    translate,
    'iHeartRadio, iHeart, Radio, Internet, Music, Talk, Listen, Live, Artist, Song, Playlist, On Demand, Discover, Personalized, Free, App, Online',
  );

  return (
    <>
      <GenericHead
        description={getShareMessage(
          translate,
          customRadioEnabled,
          onDemandEnabled,
        )}
        metaDescription={translate(
          'All Your Favorite Music. All Your Favorite Stations. All FREE. Listen to thousands of live stations or create your own artist stations. Let the music play!',
        )}
        ogType="website"
        omitTitleEnding
        title={title}
      />
      <Helmet
        meta={[
          { content: title, property: 'name' },
          { content: keywords, name: 'keywords' },
        ]}
        titleTemplate="%s"
      />
    </>
  );
}
