import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';

type Props = {
  hasBottomMargin?: boolean;
  numTiles: number;
  tilesInRow: number;
};

function DummyTiles({ hasBottomMargin, numTiles, tilesInRow }: Props) {
  const tiles = [];
  for (let i = 0; i < numTiles; i += 1) {
    tiles.push(
      <Tile
        hasBottomMargin={hasBottomMargin}
        isDummyTile
        key={i}
        tilePosition={i}
        tilesInRow={tilesInRow}
      />,
    );
  }
  return <Tiles tilesInRow={tilesInRow}>{tiles}</Tiles>;
}

export default DummyTiles;
