import CategoryTiles from '../PlaylistCategory/CategoryTiles';
import transport from 'api/transport';
import {
  PlaylistCategory as Category,
  CategoryTitle,
} from '../PlaylistCategory/primitives';
import {
  getAllAccessPreview,
  getIsTrialEligible,
  getSubscriptionType,
} from 'state/Entitlements/selectors';
import { getAmpUrl } from 'state/Config/selectors';
import { getMadeForYouRecs } from 'state/PlaylistDirectory/services';
import { getpersonalizedPlaylistRecs } from 'state/Features/selectors';
import { getProfileId, getSessionId } from 'state/Session/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  aspectRatio?: number;
  dataTest?: string;
  itemSelectedLocation: string;
  name: string;
  playedFrom: number;
  showAllTiles?: boolean;
  subtitleLines?: number;
  tilesInRow: number;
  titleLines?: number;
};

interface tilesItemProps {
  allowed?: number;
  backfillTracks?: Array<number>;
  curated?: boolean;
  dateCreated?: number;
  deletable?: boolean;
  description?: string;
  duration?: number;
  followable?: boolean;
  followed?: boolean;
  id: string;
  lastUpdated?: number;
  name: string;
  premium?: boolean;
  renameable?: boolean;
  reportingKey: string;
  shareable?: boolean;
  slug?: string;
  [key: string]: unknown;
  userId: string;
  writeable?: boolean;
}

const PlaylistPersonalized = ({
  aspectRatio = 1,
  dataTest,
  itemSelectedLocation,
  name,
  playedFrom,
  showAllTiles = false,
  subtitleLines,
  tilesInRow,
  titleLines,
}: Props) => {
  const [tiles, setTiles] = useState([]);

  const profileId = useSelector(getProfileId);
  const sessionId = useSelector(getSessionId);

  const ampUrl = useSelector(getAmpUrl);

  const includePersonalizedFlag = useSelector(getpersonalizedPlaylistRecs);
  const includePersonalized = includePersonalizedFlag;

  useEffect(() => {
    transport(
      getMadeForYouRecs({ ampUrl, profileId, sessionId, includePersonalized }),
    ).then(({ data }) => {
      const mappedData = data.tiles?.map(
        (
          tile: {
            meta: Record<string, { contentId: string; contentType: string }>;
            item: Record<string, tilesItemProps>;
          },
          index: number,
        ) => {
          const {
            id,
            urls: { image: imageUrl, web: subCategoryLink },
            name: title,
            description: subtitle,
            userId,
          } = tile.item;
          return {
            cardId: `/${id}`,
            category: {},
            children: [],
            id: { id, userId },
            isPlaylist: true,
            parent: [],
            playlistAttrs: null,
            position: index + 1,
            subCategoryLink,
            subCategoryUrl: {},
            subtitle,
            title,
            imageUrl,
          };
        },
      );
      setTiles(mappedData);
    });
  }, [ampUrl, includePersonalized, profileId, sessionId]);

  const allAccessPreview = useSelector(getAllAccessPreview);
  const isTrialEligible = useSelector(getIsTrialEligible);
  const subscriptionType = useSelector(getSubscriptionType);

  if (!tiles.length) return null;

  return (
    <Category data-test={dataTest} showBorder={false}>
      <CategoryTitle oneInstance>{name}</CategoryTitle>
      <CategoryTiles
        allAccessPreview={allAccessPreview}
        aspectRatio={aspectRatio}
        isTrialEligible={isTrialEligible}
        itemSelectedLocation={itemSelectedLocation}
        playedFrom={playedFrom}
        showAllTiles={showAllTiles}
        subscriptionType={subscriptionType}
        subtitleLines={subtitleLines}
        tiles={tiles}
        tilesInRow={tilesInRow}
        titleLines={titleLines}
      />
    </Category>
  );
};

export default PlaylistPersonalized;
