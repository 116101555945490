import styled from '@emotion/styled';
import XButton from 'components/Buttons/XButton/primitives/XButton';

const XButtonPrimitive = styled('div')({
  [XButton.toString()]: {
    height: '1.9rem',
    opacity: '0.5',
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    width: '1.9rem',
  },
});

export default XButtonPrimitive;
