import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Heading = styled('div')(({ theme }) => ({
  color: theme.colors.gray.dark,
  display: 'inline-block',
  fontSize: '2.2rem',
  fontWeight: 'bold',
  paddingBottom: '1.7rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    fontSize: '1.8rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['370'])]: {
    fontSize: '1.6rem',
    paddingBottom: '1rem',
  },
}));

export default Heading;
