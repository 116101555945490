import FilledButton from 'primitives/Buttons/FilledButton';
import ImproveRecsHeader from './primitives/ImproveRecsHeader';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PrefButtonWrapper from './primitives/PrefButtonWrapper';
import ShouldShow from 'components/ShouldShow';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import Wrapper from './primitives/Wrapper';
import { getCanLoadMore } from 'state/Recs/selectors';
import { navigate } from 'state/Routing/actions';
import { useDispatch, useSelector } from 'react-redux';
import type { FunctionComponent } from 'react';

const ImproveRecommendations: FunctionComponent<{
  onRecsClicked: () => void;
}> = ({ onRecsClicked }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const canLoadMore = useSelector(getCanLoadMore);

  const onPrefClicked = () => {
    dispatch(navigate({ path: '/my/settings' }));
  };

  return (
    <Wrapper>
      <ShouldShow shouldShow={canLoadMore}>
        <FilledButton
          aria-label={translate('See More')}
          center
          data-test="improve-recommendations-see-more"
          marginBottom
          onClick={onRecsClicked}
          styleType="cta"
        >
          {translate('See More')}
        </FilledButton>
      </ShouldShow>
      <ImproveRecsHeader>
        {translate('Improve Your Recommendations')}
      </ImproveRecsHeader>
      <PrefButtonWrapper
        log
        title={translate('Change Preferences')}
        to="/my/settings/"
      >
        <OutlinedButton
          aria-label="Change Preferences"
          center
          onClick={onPrefClicked}
        >
          {translate('Change Genre Preferences')}
        </OutlinedButton>
      </PrefButtonWrapper>
    </Wrapper>
  );
};

export default ImproveRecommendations;
