import * as UPSELL_FROM from 'modules/Analytics/constants/upsellFrom';
import analytics from 'modules/Analytics';
import Banner from '../Banner';
import UPSELL from 'constants/upsellTracking';
import useLocalStorage from 'hooks/useLocalStorage';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import {
  getAllAccessPreview,
  getIsTrialEligible,
  makeEntitlementsSelector,
} from 'state/Entitlements/selectors';
import { getUpgradeLink } from 'state/Links/selectors';
import { navigateToSubscriptionFrame } from 'utils/subscription';
import { RECURLY_SUBSCRIPTION_ID } from 'constants/subscriptionConstants';
import { useSelector } from 'react-redux';
import type { State } from 'state/types';

const EXPIRE_AFTER_RELOADS_COUNT = 100;
const LOCAL_STORAGE_KEY = 'home_upsell_banner_count';

function ForYouUpsell() {
  const allAccessPreview = useSelector<State, boolean>(getAllAccessPreview);
  const showUpsellBanner = useSelector(
    makeEntitlementsSelector('SHOW_UPSELL_BANNER_FORYOU_WEB'),
  );
  const isTrialEligible = useSelector<State, boolean>(getIsTrialEligible);
  const upgrade = useSelector<State, string>(getUpgradeLink);
  const translate = useTranslate();
  const [count, setCount] = useLocalStorage<number>(LOCAL_STORAGE_KEY, 0);

  const show =
    isTrialEligible &&
    showUpsellBanner &&
    (count < EXPIRE_AFTER_RELOADS_COUNT || allAccessPreview);

  useMount(() => {
    if (!show) return;
    setCount(count + 1);
  });

  function onClick(): void {
    analytics.trackUpsellOpen!({
      destination: UPSELL_FROM.NEW_SCREEN,
      promotionSubscriptionTier: UPSELL_FROM.ALL_ACCESS,
      sku: UPSELL_FROM.IHEART_US_PREMIUM_TRIAL,
      upsellFrom: UPSELL_FROM.FOR_YOU_UPGRADE_BANNER,
      upsellType: UPSELL_FROM.BANNER,
      vendor: UPSELL_FROM.NATIVE,
    });

    if (allAccessPreview) {
      window.location.href = `${upgrade}?upsellFrom=${UPSELL.FOR_YOU_UPGRADE_BANNER.id}`;
    } else {
      navigateToSubscriptionFrame(RECURLY_SUBSCRIPTION_ID.IHEART_US_PREMIUM, {
        upsellFrom: UPSELL.FOR_YOU_UPGRADE_BANNER,
      });
    }
  }

  const headingText =
    allAccessPreview ?
      `🌟 ${translate('You have All Access')} 🌟`
    : translate('Upgrade to All Access');

  const contentText =
    allAccessPreview ?
      translate('Want to keep enjoying these perks after the weekend?')
    : translate(
        'Listen offline, unlimited skips, save and play songs from the radio.',
      );

  const buttonText =
    allAccessPreview ?
      translate('Learn More')
    : translate('Start 30 Day Free Trial');

  if (!show) return null;

  return (
    <Banner
      allAccessPreview={allAccessPreview}
      buttonText={buttonText}
      contentText={contentText}
      dataTest="Start-Trial-Banner"
      headingText={headingText}
      onClick={onClick}
      onClose={() => setCount(9999999)}
    />
  );
}

export default ForYouUpsell;
