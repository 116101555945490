import logger, { CONTEXTS } from 'modules/Logger';
import NavLink from 'components/NavLink';
import RecReason from 'components/RecReason/RecReasonPrimitive';
import transport from 'api/transport';
import withCancel from 'utils/withCancel';
import { Component } from 'react';
import {
  CUSTOM_TYPES,
  DEFAULT,
  FACEBOOK_TYPES,
  FAVORITES,
  GENRE,
  LIVE_TYPES,
  MARKET,
} from './constants';
import { get } from 'lodash-es';
import { getArtistByArtistId } from 'state/Artists/services';
import { getArtistUrl, getGenreUrl, getLiveUrl } from 'utils/url';
import { getLiveStationById } from 'state/Live/services';
import { getMarketByIdQuery } from 'state/Location/services';
import { slugify } from 'utils/string';
import { STATION_TYPE } from 'constants/stationTypes';

export default class RecReasonView extends Component {
  static propTypes = {
    id: PropTypes.number,
    type: PropTypes.string,
  };

  componentDidMount() {
    this.query = withCancel(this.queryReason());
    this.query
      .then(({ forceDefault = false, name, url }) =>
        // eslint-disable-next-line react/no-unused-state
        this.setState({ forceDefault, name, url }),
      )
      .catch(error => {
        const errObj = error instanceof Error ? error : new Error(error);
        logger.error([CONTEXTS.RECS], error, {}, errObj);
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const shouldRefetch = [
      'id',
      'type',
      'countryCode',
      'ampUrl',
      'translate',
    ].some(key => this.props[key] !== nextProps[key]);
    if (shouldRefetch) {
      this.queryReason().then(({ forceDefault = false, name, url }) =>
        // eslint-disable-next-line react/no-unused-state
        this.setState({ forceDefault, name, url }),
      );
    }
  }

  componentWillUnmount() {
    this.query.cancel();
  }

  getReasonString(name) {
    const { type, id, translate } = this.props;

    if (!type && !id) {
      return translate('Recently Played');
    }

    const { forceDefault } = this.state;
    if (FACEBOOK_TYPES.includes(type)) {
      return translate('You like {station} on Facebook', { station: name });
    }
    if ([...LIVE_TYPES, ...CUSTOM_TYPES].includes(type)) {
      return translate('You listened to {station}', { station: name });
    }
    switch (forceDefault ? DEFAULT : type) {
      case MARKET:
        return translate('You are near {city}', { city: name });
      case GENRE:
        return translate('You like {genre}', { genre: name });
      case FAVORITES:
        return translate('Just For You');
      default:
        return translate('Popular On {iHeart}', { iHeart: name });
    }
  }

  queryReason() {
    const { id, type, countryCode, ampUrl, genresRequest } = this.props;

    if (LIVE_TYPES.includes(type)) {
      return transport(getLiveStationById({ ampUrl, id })).then(
        ({
          data: {
            hits: [{ name, id: stationId }],
          },
        }) => ({
          name,
          url: getLiveUrl(stationId, name),
        }),
      );
    }
    if (CUSTOM_TYPES.includes(type)) {
      return transport(
        getArtistByArtistId({ ampUrl, artistId: id, countryCode }),
      )
        .then(({ data }) => data.artist)
        .then(({ artistName, artistId }) => ({
          name: artistName,
          url: getArtistUrl(artistId, artistName),
        }));
    }
    switch (type) {
      case MARKET:
        return transport(getMarketByIdQuery({ ampUrl, id })).then(res => {
          const { city, stateAbbreviation, countryAbbreviation, marketId } =
            get(res, ['data'], {});

          return {
            name: `${city}, ${stateAbbreviation}`,
            url: `/live/country/${countryAbbreviation}/city/${slugify(
              city,
            )}-${marketId}`,
          };
        });
      case GENRE:
        return (
          !Object.keys(this.props.genres).length ?
            genresRequest()
          : Promise.resolve()).then(() => {
          const genre = this.props.genres[id];
          return genre ?
              {
                name: genre.name,
                url: getGenreUrl(genre.id, genre.name),
              }
            : {
                forceDefault: true,
                name: 'iHeartRadio',
                url: '/',
              };
        });
      default:
        return Promise.resolve({
          name: 'iHeartRadio',
          url: '/',
        });
    }
  }

  render() {
    const { name, url } = this.state || {};
    const { seedType, showDescriptionGradient } = this.props;
    const nameLink = url && name ? <NavLink to={url}>{name}</NavLink> : name;
    if (!name) {
      return <span />;
    }

    const reason = this.getReasonString(nameLink);
    return (
      <RecReason
        artistDescription={seedType === STATION_TYPE.ARTIST}
        showDescriptionGradient={showDescriptionGradient}
      >
        {reason}
      </RecReason>
    );
  }
}
